import { useState } from 'react';
import { useEffectOnce } from 'react-use';

/**
 * useScript inserts a `script` html element into the page, removing it on unmount. Removing on unmount can be disabled by setting cleanup = false.
 * @param url the address of the script contents to be loaded.
 */
export const useScript = (
  url: string,
  cleanup = true,
): { loading: boolean } => {
  const [loading, setLoading] = useState(true);
  useEffectOnce(() => {
    const scripts = document.getElementsByTagName('script');
    const existingScript = Object.values(scripts).find(
      (script) => script.src === url,
    );

    if (existingScript && existingScript.getAttribute('loaded')) {
      setLoading(false);
      return (): void => {
        if (cleanup && document.body.contains(existingScript)) {
          document.body.removeChild(existingScript);
        }
      };
    }

    const script = document.createElement('script');
    script.src = url;

    script.addEventListener('load', () => {
      script.setAttribute('loaded', 'true');
      setLoading(false);
    });

    document.body.appendChild(script);

    return (): void => {
      if (cleanup && document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  });

  return { loading };
};